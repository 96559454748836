export enum QueryKey {
  SegmentGroups = 'segmentGroups',
  Promotions = 'promotions',
  PromotionEmailOptions = 'promotionEmailOptions',
  TemplateDetails = 'templateDetails',
  Players = 'players',
  PlayersPartial = 'playersPartial',
  FilterCards = 'filterCards',
  AssignedFilterCards = 'assignedFiltercards',
  FilterCardsWithoutAssigned = 'filterCardsWithoutAssigned',
  JourneyGroups = 'journeyGroups',
  Comments = 'comments',
  Users = 'users',
  DashboardKpi = 'dashboardKpi',
  PlayerKpi = 'playerKpi',
  PlayerWalletHistory = 'playerWalletHistory',
  BrandBonusesHistory = 'brandBonusesHistory',
  BrandMailingHistory = 'brandMailingHistory',
  EmailEvents = 'emailEvents',
  EmailLogs = 'emailLogs',
  PromoStats = 'promoStats',
  KanbanStages = 'kanbanStages',
  JourneyStagesDates = 'journeyStagesDates',
  StageStats = 'stageStats',
  Brands = 'brands',
  GetPopups = 'GetPopups',
  GetPopupsScheduleItems = 'GetPopupsScheduleItems'
}
