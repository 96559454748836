export enum AppConsts {
  AccessToken = 'access_token'
}

export enum Role {
  Admin = 'Admin',
  Owner = 'Owner',
  CampaignManager = 'CampaignManager',
  Marketing = 'Marketing',
  MarketingLead = 'MarketingLead',
  EmailOperator = 'EmailOperator',
  WheelOperator = 'WheelOperator',
  PopupOperator = 'PopupOperator'
}

export enum IRole {
  Owner = 'Owner',
  CampaignManager = 'CampaignManager',
  Marketing = 'Marketing',
  MarketingLead = 'MarketingLead',
  WheelOperator = 'WheelOperator'
  //EmailOperator = 'EmailOperator'
}

export enum ScreenType {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}
