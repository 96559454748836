import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
//* MUI
import { Box } from '@mui/material';
//* MUI icons
import {
  CachedOutlined,
  CheckCircleOutlined,
  QueryBuilderOutlined,
  RemoveCircleOutline
} from '@mui/icons-material';
//* Components
import Header from '../../Common/Layout/Header';
import Loader from '../../Common/Global/Loader';
import AddButton from '../../Common/Buttons/AddButton';
import KanbanCard from '../../Common/Kanban/KanbanCard';
import KanbanBoard from '../../Common/Kanban/KanbanBoard';
import SchedulePopupsDialogForm from './SchedulePopupsDialogForm/SchedulePopupsDialogForm';
//* Models
import { IPopupSchedule, IPopupScheduleStage } from '../../../models/popups';
import { KanbanItem, KanbanStage } from '../../../models/global';
//* Enums
import { Actions, PromotionStage } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
//* Queries
import {
  DeletePopupSchedule,
  GetPopupsScheduleItemsQuery,
  UpdatePopupScheduleStage
} from '../../../queries/popups';
//* Utils
import { getBrandId, handleRequestError } from '../../../utils/ui';

type StageItems = {
  [key: string]: KanbanItem[];
};

const ManagePopups: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);

  const [stages, setStages] = useState<KanbanStage[]>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [popupScheduleItem, setPopupScheduleItem] = useState<IPopupSchedule>();
  const [popupsScheduleItems, setPopupsScheduleItems] = useState<IPopupSchedule[]>([]);

  const getPopupsScheduleItems = useQuery({
    queryKey: [QueryKey.GetPopupsScheduleItems, brandId],
    queryFn: () => GetPopupsScheduleItemsQuery(brandId),
    onSuccess: ({ data }) => {
      setPopupsScheduleItems(data);
    },
    refetchOnMount: 'always',
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const deletePopupSchedule = useMutation(DeletePopupSchedule, {
    onSuccess: ({ data }) => {
      const group = popupsScheduleItems.find((g) => g.id === data);

      dispatch({
        type: Actions.HideConfirmation
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Pop-up schedule item "${group?.name}" deleted`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopupsScheduleItems, brandId])
  });

  const updateStagePopupSchedule = useMutation(UpdatePopupScheduleStage, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'info',
          text: `Pop-up schedule updated`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopupsScheduleItems, brandId])
  });

  const selectPopupScheduleItem = (id: number) => {
    const popupScheduleItem = popupsScheduleItems.find((p) => p.id === id);
    if (popupScheduleItem) {
      setDialogIsOpen(true);
      setPopupScheduleItem(popupScheduleItem as IPopupSchedule);
    }
  };

  const handleEdit = (id: number) => {
    selectPopupScheduleItem(id);
  };

  const handleDelete = (id: number) => {
    const popupScheduleItem = popupsScheduleItems.find((p) => p.id === id);

    if (popupScheduleItem) {
      dispatch({
        type: Actions.ShowConfirmation,
        payload: {
          text: `Are you sure you want to delete "${popupScheduleItem.name}" pop-up schedule`,
          agreeAction: () => {
            dispatch({
              type: Actions.HideConfirmation
            });
            deletePopupSchedule.mutate(id);
          }
        }
      });
    } else {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: `Invalid pop-up schedule id - ${id}`,
          autoHide: 6000
        }
      });
    }
  };

  const buildStages = useCallback(() => {
    const items = {
      pending: [],
      active: [],
      finished: [],
      deactivated: []
    } as StageItems;

    const now = dayjs();

    popupsScheduleItems.forEach((p) => {
      const popupSchedule = {
        id: p.id,
        title: p.name,
        startDate: p.startDate,
        endDate: p.endDate,
        handleEdit,
        handleDelete
      } as KanbanItem;

      if (p.isDeactivated) {
        items.deactivated.push(popupSchedule);
      } else {
        if (dayjs(p.startDate).isBefore(now)) {
          const finished = dayjs(p.endDate).isBefore(now);
          if (finished) {
            items.finished.push(popupSchedule);
          } else {
            items.active.push(popupSchedule);
          }
        } else {
          items.pending.push(popupSchedule);
        }
      }
    });

    const result = [
      {
        title: PromotionStage.Pending,
        items: items.pending.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate))),
        icon: <QueryBuilderOutlined sx={{ color: '#3364E1' }} />
      },
      {
        title: PromotionStage.Active,
        items: items.active.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate))),
        icon: <CachedOutlined sx={{ color: '#FFDD00' }} />
      },
      {
        title: PromotionStage.Finished,
        items: items.finished.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))),
        icon: <CheckCircleOutlined sx={{ color: '#36CD33' }} />
      },
      {
        title: PromotionStage.Deactivated,
        items: items.deactivated.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))),
        icon: <RemoveCircleOutline sx={{ color: '#EF2929' }} />
      }
    ] as KanbanStage[];

    setStages(result);
  }, [popupsScheduleItems]);

  useEffect(() => {
    buildStages();
  }, [popupsScheduleItems]);

  const renderCard = ({ item, index }: { item: any; index: number }): React.ReactNode => {
    return <KanbanCard key={`card-${index}`} item={item} index={index} />;
  };

  const handleItemStageChange = (sourceName: string, destinationName: string, item: any) => {
    if (sourceName !== destinationName) {
      const movedPopupSchedule = item as IPopupSchedule;
      const now = dayjs();
      const start = dayjs(movedPopupSchedule.startDate);
      const end = dayjs(movedPopupSchedule.endDate);

      switch (destinationName) {
        case PromotionStage.Pending:
          movedPopupSchedule.startDate = now.add(1, 'day').startOf('day').toDate();

          if (end.isBefore(now)) {
            movedPopupSchedule.endDate = now
              .add(2, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();

            movedPopupSchedule.isDeactivated = false;
          }
          break;
        case PromotionStage.Active:
          if (start.isAfter(now)) {
            movedPopupSchedule.startDate = now.add(-1, 'day').startOf('day').toDate();
          }
          if (end.isBefore(now)) {
            movedPopupSchedule.endDate = now
              .add(2, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();

            movedPopupSchedule.isDeactivated = false;
          }
          break;
        case PromotionStage.Finished:
          if (end.isAfter(now)) {
            movedPopupSchedule.endDate = now
              .add(-1, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();

            movedPopupSchedule.isDeactivated = false;
          }
          if (dayjs(movedPopupSchedule.endDate).isBefore(start)) {
            movedPopupSchedule.startDate = dayjs(movedPopupSchedule.endDate)
              .add(-1, 'day')
              .toDate();
          }
          break;
        case PromotionStage.Deactivated:
          movedPopupSchedule.isDeactivated = true;
          break;
        default:
          break;
      }

      const updated = {
        ...movedPopupSchedule,
        startDate: dayjs(movedPopupSchedule.startDate).utc(true).format(),
        endDate: dayjs(movedPopupSchedule.endDate).utc(true).format()
      } as IPopupScheduleStage;

      updateStagePopupSchedule.mutate(updated);
    }
  };

  return (
    <Box className="nav-page popups">
      <Box id="popup-group-header-info">
        <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Header text="Schedule Pop-ups" />
            <Header text="Schedule pop-ups" isSubHeader={true} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
            <AddButton
              id="add-filter-overview-button"
              text="+ Add Pop-up Schedule"
              onClick={() => setDialogIsOpen(true)}
            />
          </Box>
        </Box>
      </Box>
      <KanbanBoard
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        stages={stages}
        handleStageChange={handleItemStageChange}
        renderCard={renderCard}
      />
      <Loader loading={getPopupsScheduleItems.isLoading} />
      {dialogIsOpen && (
        <SchedulePopupsDialogForm
          popupScheduleItem={popupScheduleItem}
          onClose={() => {
            setDialogIsOpen(false);
            setPopupScheduleItem(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default ManagePopups;
