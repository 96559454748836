import React, { useContext, useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { AppContext } from '../../../../AppContext';
import { Box } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
//* Components
import Loader from '../../../Common/Global/Loader';
import DialogBox from '../../../Common/Layout/DialogBox';
import MainButton from '../../../Common/Buttons/MainButton';
import CancelButton from '../../../Common/Buttons/CancelButton';
import IgaStepper from '../../../Common/Layout/Stepper/IgaStepper';
// import SegmentGroup from '../../Segments/SegmentGroup/SegmentGroup';
import PopupHtmlContentStep from './PopupsDialogFormSteps/PopupHtmlContentStep/PopupHtmlContentStep';
import PopupsDialogFormDetailsStep from './PopupsDialogFormSteps/PopupsDialogFormDetailsStep/PopupsDialogFormDetailsStep';
//* Models
import { IPopup } from '../../../../models/popups';
// import { Segment } from '../../../../models/segmets';
//* Enums
import { Actions } from '../../../../enums/ActionEnums';
import { QueryKey } from '../../../../enums/HttpRequestKeyEnums';
//* Queries
// import { SegmentGroupsQuery } from '../../../../queries/segment';
import { CreatePopup, UpdatePopup } from '../../../../queries/popups';
//* Utils
import { getBrandId, /*, getActiveSegments, */ handleRequestError } from '../../../../utils/ui';

dayjs.extend(utc);

interface IProps {
  popupData?: IPopup;
  onClose: () => void;
}

const PopupsDialogForm: React.FunctionComponent<IProps> = ({ popupData, onClose }: IProps) => {
  const queryClient = useQueryClient();
  const steps = ['Details', 'Pop-up Content'];
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);

  const [activeStep, setActiveStep] = useState(0);
  const [popupDetails, setPopupDetails] = useState<IPopup>(
    popupData || {
      name: '',
      description: '',
      isActive: false,
      htmlContent: ''
    }
  );

  const createPopup = useMutation(CreatePopup, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Created ${data.name} popup`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopups])
  });

  const updatePopup = useMutation(UpdatePopup, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Updated ${data.name} popup`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopups])
  });

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <PopupsDialogFormDetailsStep
            popupDetails={popupDetails}
            setPopupDetails={setPopupDetails}
          />
        );
      case 1:
        return (
          <PopupHtmlContentStep popupDetails={popupDetails} setPopupDetails={setPopupDetails} />
        );
      default:
        return <h3>Step not found</h3>;
    }
  };

  const stepIsValid = (): boolean => {
    switch (activeStep) {
      case 0:
        return popupDetails.name.length > 2;
      case 1:
        return popupDetails.htmlContent.length > 3;
      default:
        return false;
    }
  };

  const handleNextClicked = () => {
    if (activeStep === 1) {
      if (popupData && popupData.id) {
        updatePopup.mutate({ popupDetails, id: popupData.id });
      } else {
        createPopup.mutate({ popupDetails, brandId });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <DialogBox
      width={1400}
      onClose={onClose}
      title={popupData ? 'Edit Pop-up' : 'Create New Pop-up'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IgaStepper steps={steps} activeStep={activeStep}>
          {getStepContent()}
        </IgaStepper>
        <Box className="form-actions" sx={{ mt: '16px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              id="popup-back"
              text="Back"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="popup-next"
              text={activeStep + 1 === steps.length ? 'Save' : 'Next'}
              onClick={handleNextClicked}
              disabled={!stepIsValid()}
            />
          </Box>
        </Box>
      </Box>
      <Loader loading={createPopup.isLoading || updatePopup.isLoading} />
    </DialogBox>
  );
};

export default PopupsDialogForm;
