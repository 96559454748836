import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Link } from '@mui/material';
import Header from '../../Common/Layout/Header';
import AddButton from '../../Common/Buttons/AddButton';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import { displayText, getBrandId, handleRequestError } from '../../../utils/ui';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import PromotionForm from './PromotionForm';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../../AppContext';
import { DeletePromotionGroupMutation, PromotionsGroupsQuery } from '../../../queries/promotion';
import { IPromotion, IPromotionGroup } from '../../../models/promotion';
import Loader from '../../Common/Global/Loader';
import PromoGroupForm from './PromoGroupForm';
import { Actions } from '../../../enums/ActionEnums';
import { AuthRoutes, RouteNames } from '../../../enums/RouteEnums';
import { IRouteItem } from '../../../models/global';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadcrumbsNav from '../../Navigation/BreadcrumbsNav';
import GroupDetails from './GroupDetails';

const index: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, state } = useContext(AppContext);
  const [openForm, setOpenForm] = useState(false);
  const [openGroupForm, setOpenGroupForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IPromotionGroup | null>(null);
  const pathElements = location.pathname.split('/').filter(Boolean);
  const [isDetails, setIsDetails] = useState(pathElements.length === 2);
  const [currentRoute, setCurrentRoute] = useState<string>(
    isDetails ? location.state?.promotionGroup?.name : RouteNames.Promotions
  );
  const [routeItems, setRouteItems] = useState<IRouteItem[]>(
    isDetails ? [{ name: RouteNames.Promotions, path: AuthRoutes.Promotions }] : []
  );
  const [groupPromotions, setGroupPromotions] = useState<IPromotion[]>([]);
  const fnKey = QueryKey.Promotions;
  const brandId = getBrandId(state.selectedBrand);

  const promotionGroupsQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => PromotionsGroupsQuery(brandId),
    onSuccess: ({ data }) => {
      setGroups(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  useEffect(() => {
    const data = (promotionGroupsQuery.data?.data as IPromotionGroup[]) ?? [];
    setGroups(data);
    if (selectedGroup) {
      const updatedGroup = data.find((d) => d.id === selectedGroup.id);
      setSelectedGroup(updatedGroup ?? null);
      setGroupPromotions(updatedGroup?.promotions || groupPromotions);
    }
  }, [promotionGroupsQuery.data]);

  useEffect(() => {
    if (!location.state?.promotionGroup) {
      setIsDetails(false);
      setCurrentRoute(RouteNames.Promotions);
      setRouteItems([]);
      setGroupPromotions([]);
    }
  }, [location.state]);

  const [groups, setGroups] = useState<IPromotionGroup[]>(promotionGroupsQuery.data?.data || []);

  useEffect(() => {
    if (isDetails && groups.length > 0 && groupPromotions.length === 0) {
      const foundGroup = groups.find((g) => g.name === currentRoute);
      if (foundGroup) {
        const promos = foundGroup.promotions;
        setGroupPromotions(promos.map((p) => ({ ...p, group: foundGroup })));
      }
    }
  }, [groups]);

  const deleteGroup = useMutation(DeletePromotionGroupMutation, {
    onSuccess: ({ data }) => {
      const group = groups.find((g) => g.id === data);
      dispatch({
        type: Actions.HideConfirmation
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Promotion group ${group?.name} deleted`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const columns = [
    {
      title: 'Group name',
      field: 'name',
      render: (data: IPromotionGroup) => (
        <Link underline="hover" color="info.main" onClick={() => handleDetails(data)}>
          {data.name}
        </Link>
      )
    },
    {
      title: 'Number Of Promotions',
      render: (rowData: IPromotionGroup) => displayText(rowData.promotions.length.toString())
    }
  ] as IHeadCell[];

  const handleDelete = (rowData: IPromotionGroup) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${rowData.name}?`,
        agreeAction: () => deleteGroup.mutate(rowData.id)
      }
    });
  };

  const customActions = ({ rowData }: { rowData: IPromotionGroup }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton
          onClick={() => {
            setSelectedGroup(rowData);
            setOpenGroupForm(true);
          }}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <EditOutlined sx={{ color: 'primary.main' }} />
        </IconButton>
        <IconButton
          onClick={() => handleDelete(rowData)}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
        </IconButton>
      </Box>
    );
  };

  const handleDetails = (rowData: IPromotionGroup) => {
    setCurrentRoute(rowData.name);
    const items = [{ name: RouteNames.Promotions, path: AuthRoutes.Promotions }];
    if (isDetails) {
      items.push({
        name: RouteNames.Promotions,
        path: `${location.pathname}` as AuthRoutes
      });
    }
    setRouteItems([{ name: RouteNames.Promotions, path: AuthRoutes.Promotions }]);

    navigate(`${location.pathname}/${rowData.id}`, {
      state: { promotionGroup: rowData, path: AuthRoutes.Promotions }
    });
    setIsDetails(true);
    setSelectedGroup(rowData);
    setGroupPromotions(rowData.promotions);
  };

  const key = `group-${selectedGroup?.promotions.map((promo) => promo.id).join('-')}`;

  return (
    <Box className="nav-page">
      <Box id="promo-group-header-info">
        <BreadcrumbsNav current={currentRoute} items={routeItems} />
        <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Header text="Promotions" />
            <Header text="Add or edit different promotions" isSubHeader={true} />
          </Box>
          {isDetails && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
              <AddButton
                id="add-filter-overview-button"
                text="+ Add Promotion"
                onClick={() => setOpenForm(true)}
              />
            </Box>
          )}
        </Box>
      </Box>
      {isDetails ? (
        <GroupDetails
          key={key}
          promotions={groupPromotions}
          group={selectedGroup?.name ?? ''}
          groups={groups}
        />
      ) : (
        <CustomTable
          toolbarTitle="Groups"
          columns={columns}
          data={groups}
          Actions={customActions}
          showSearch
          createButtonProps={{
            id: 'add-promotion-button',
            onClick: () => setOpenForm(true),
            text: '+ Add Promotion',
            disabled: false
          }}
        />
      )}
      {openForm && <PromotionForm onClose={() => setOpenForm(false)} groups={groups} />}
      {openGroupForm && (
        <PromoGroupForm
          group={selectedGroup as IPromotionGroup}
          onClose={() => {
            setOpenGroupForm(false);
            setSelectedGroup(null);
          }}
        />
      )}
      <Loader loading={promotionGroupsQuery.isLoading || deleteGroup.isLoading} />
    </Box>
  );
};

export default index;
