import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../../AppContext';
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material';
//* MUI icons
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
//* Components
import Header from '../../Common/Layout/Header';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import PopupsDialogForm from './PopupsDialogForm/PopupsDialogForm';
//* Models
import { IPopup } from '../../../models/popups';
//* Enums
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
//* Queries
import { DeletePopup, GetPopups, UpdatePopupIsActive } from '../../../queries/popups';
//* Utils
import { getBrandId, handleRequestError } from '../../../utils/ui';

const ManagePopups: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);

  const brandId = getBrandId(state.selectedBrand);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedPopupData, setSelectedPopupData] = useState<IPopup>();

  const getPopupsQuery = useQuery({
    queryKey: [QueryKey.GetPopups],
    queryFn: () => GetPopups({ brandId }),
    onSuccess: ({ data }) => {
      setPopupsData(data.data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const [popupsData, setPopupsData] = useState(getPopupsQuery.data?.data ?? []);

  useEffect(() => {
    if (getPopupsQuery.data?.data) {
      setPopupsData(getPopupsQuery.data.data);
    }
  }, [getPopupsQuery.data]);

  const deletePopup = useMutation(DeletePopup, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Pop-up deleted'
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopups])
  });

  const handleDeletePopup = (popupData: IPopup) => {
    if (popupData && popupData.id) {
      dispatch({
        type: Actions.ShowConfirmation,
        payload: {
          text: `Are you sure you want to delete pop-up "${popupData.name}"?`,
          agreeAction: () => {
            dispatch({
              type: Actions.HideConfirmation
            });

            if (popupData.id) {
              deletePopup.mutate(popupData.id);
            }
          }
        }
      });
    }
  };

  const setPopupIsActive = useMutation(UpdatePopupIsActive, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const updatedEvent = data as IPopup;
      const text = updatedEvent.isActive ? 'activated' : 'deactivated';

      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Popup ${text}`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.GetPopups])
  });

  const columns = [
    {
      title: 'Name',
      field: 'name'
    },
    {
      title: 'Description',
      field: 'description'
    },
    {
      title: 'Status',
      render: (data) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.isActive}
              color="success"
              onChange={() => setPopupIsActive.mutate(data)}
            />
          }
          label={data.isActive ? 'Active' : 'Inactive'}
        />
      )
    }
  ] as IHeadCell[];

  const customActions = ({ rowData }: { rowData: IPopup }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton
          onClick={() => {
            setSelectedPopupData(rowData);
            setDialogIsOpen(true);
          }}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <EditOutlined sx={{ color: 'primary.main' }} />
        </IconButton>
        <IconButton
          onClick={() => handleDeletePopup(rowData)}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box className="nav-page popups">
      <Box id="popups-group-header-info">
        <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Header text="Manage Pop-ups" />
            <Header text="Add or edit different pop-ups" isSubHeader={true} />
          </Box>
        </Box>
      </Box>
      <CustomTable
        showSearch
        data={popupsData}
        columns={columns}
        Actions={customActions}
        toolbarTitle={'Pop-ups'}
        createButtonProps={{
          id: 'add-custom-event-button',
          onClick: () => {
            setSelectedPopupData(undefined);
            setDialogIsOpen(true);
          },
          text: '+ Add Popup',
          disabled: false
        }}
        loading={getPopupsQuery.isLoading}
      />
      {dialogIsOpen && (
        <PopupsDialogForm popupData={selectedPopupData} onClose={() => setDialogIsOpen(false)} />
      )}
    </Box>
  );
};

export default ManagePopups;
