import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Box } from '@mui/material';
//* Components
import InputComponent from '../../../../../Common/Fields/InputComponent';
//* Models
import { IPopup } from '../../../../../../models/popups';
//* Enums
//* Queries
//* Utils
//* Styles
import './PopupsDialogFormDetailsStep.scss';

dayjs.extend(utc);

interface IProps {
  popupDetails: IPopup;
  setPopupDetails: (value: IPopup) => void;
}

const PopupsDialogFormDetailsStep: React.FunctionComponent<IProps> = ({
  popupDetails,
  setPopupDetails
}: IProps) => {
  const handleSetPopupDetails = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setPopupDetails({ ...popupDetails, [field]: event.target.value });
  };

  return (
    <Box className="popup__details-step">
      <InputComponent
        id="popup-name"
        label="Pop-up Name"
        value={popupDetails.name}
        onChange={(event) => handleSetPopupDetails('name', event)}
      />
      <InputComponent
        id="popup-description"
        label="Pop-up Description"
        value={popupDetails.description}
        onChange={(event) => handleSetPopupDetails('description', event)}
      />
    </Box>
  );
};

export default PopupsDialogFormDetailsStep;
