import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControlLabel, IconButton, Switch } from '@mui/material';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import { Email } from '../../models/email';
import { DeleteOutlineOutlined, EditOutlined, Visibility } from '@mui/icons-material';
import EmailForm from './EmailForm';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { getBrandId, handleRequestError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  DeleteEmailEventMutation,
  EmailEventsIsActiveMutation,
  EmailEventsQuery
} from '../../queries/email';
import Loader from '../Common/Global/Loader';
import { Actions } from '../../enums/ActionEnums';

const EmailsOverview: React.FunctionComponent = () => {
  const { state, dispatch } = useContext(AppContext);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const [openForm, setOpenForm] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const queryClient = useQueryClient();

  const fnKey = QueryKey.EmailEvents;
  const brandId = getBrandId(state.selectedBrand);

  const emailEventQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => EmailEventsQuery(brandId),
    onSuccess: ({ data }) => {
      const dbEvents = data as Email[];
      setEmailEvents(dbEvents);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });
  const [emailEvents, setEmailEvents] = useState<Email[]>(emailEventQuery.data?.data ?? []);

  useEffect(() => {
    if (emailEventQuery.data?.data) {
      setEmailEvents(emailEventQuery.data.data);
    }
  }, [emailEventQuery.data]);

  const setIsActive = useMutation(EmailEventsIsActiveMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const updatedEvent = data as Email;
      const text = updatedEvent.isActive ? 'activated' : 'deactivated';
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${updatedEvent.eventDisplayName} ${text}`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const deleteEvent = useMutation(DeleteEmailEventMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Email Event deleted'
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const handleSetActive = (emailEvent: Email) => {
    if (emailEvent.template && emailEvent.sender) {
      setIsActive.mutate({
        brandId,
        emailEventId: emailEvent.id
      });
    } else {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: 'You should select sender and template first'
        }
      });
    }
  };

  const handleDelete = (emailEvent: Email) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${emailEvent.eventDisplayName} email event`,
        agreeAction: () => {
          dispatch({
            type: Actions.HideConfirmation
          });
          deleteEvent.mutate(emailEvent.id);
        }
      }
    });
  };

  const columns = [
    {
      title: 'Event',
      field: 'eventDisplayName'
    },
    {
      title: 'Status',
      render: (data: Email) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.isActive}
              color="success"
              onChange={() => handleSetActive(data)}
            />
          }
          label={data.isActive ? 'Active' : 'Inactive'}
        />
      )
    },
    {
      title: 'Template',
      field: 'template'
    },
    {
      title: 'Description',
      field: 'description'
    }
  ] as IHeadCell[];

  const customActions = ({ rowData }: { rowData: Email }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        {rowData.template && (
          <IconButton
            onClick={() => {
              setSelectedEmail(rowData);
              setIsPreview(true);
              setOpenForm(true);
            }}
            sx={{ ...iconButtonProps, marginRight: 0 }}
          >
            <Visibility sx={{ color: 'primary.main' }} />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            setSelectedEmail(rowData);
            setOpenForm(true);
          }}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <EditOutlined sx={{ color: 'primary.main' }} />
        </IconButton>
        {rowData.isCustom && (
          <IconButton
            onClick={() => handleDelete(rowData)}
            sx={{ ...iconButtonProps, marginRight: 0 }}
          >
            <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box className="nav-page">
      <Box sx={{ pt: '50px' }}>
        <CustomTable
          toolbarTitle="Set Transactional Emails"
          data={emailEvents}
          columns={columns}
          showSearch
          createButtonProps={{
            id: 'add-custom-event-button',
            onClick: () => setOpenForm(true),
            text: '+ Add Event',
            disabled: false
          }}
          Actions={customActions}
        />
      </Box>
      {openForm && (
        <EmailForm
          email={selectedEmail}
          isPreview={isPreview}
          onClose={() => {
            setSelectedEmail(null);
            setOpenForm(false);
            setIsPreview(false);
          }}
        />
      )}
      <Loader loading={emailEventQuery.isLoading || deleteEvent.isLoading} />
    </Box>
  );
};

export default EmailsOverview;
