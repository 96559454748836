import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  EmailEvent,
  IPromoStage,
  IPromotion,
  IPromotionGroup,
  PromoStat
} from '../../../models/promotion';
import KanbanBoard from '../../Common/Kanban/KanbanBoard';
import { KanbanItem, KanbanStage } from '../../../models/global';
import {
  CachedOutlined,
  CheckCircleOutlined,
  RemoveCircleOutline,
  QueryBuilderOutlined
} from '@mui/icons-material';
import { AppContext } from '../../../AppContext';
import { Actions, PromotionStage } from '../../../enums/ActionEnums';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeletePromotion, UpdatePromotionStage } from '../../../queries/promotion';
import { GetCsvData, getBrandId, handleRequestError } from '../../../utils/ui';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { Box } from '@mui/material';
import PromotionForm from './PromotionForm';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import KanbanCard from '../../Common/Kanban/KanbanCard';
import PromoStats from './PromoStats';
import { PromoStatsQuery } from '../../../queries/email';
import Loader from '../../Common/Global/Loader';

dayjs.extend(utc);

interface IProps {
  promotions: IPromotion[];
  group: string;
  groups: IPromotionGroup[];
}

type StageItems = {
  [key: string]: KanbanItem[];
};

const GroupDetails: React.FunctionComponent<IProps> = ({ promotions, group, groups }) => {
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const brandId = getBrandId(state.selectedBrand);

  const [stages, setStages] = useState<KanbanStage[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState<IPromotion | null>(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [statsId, setStatsId] = useState(-1);

  const deletePromo = useMutation(DeletePromotion, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'info',
          text: `Deleted promotion ${data}`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const statsData = useMutation({
    mutationFn: PromoStatsQuery,
    mutationKey: [QueryKey.PromoStats, brandId, statsId],
    cacheTime: 5 * 60 * 1000,
    onSuccess: ({ data }) => {
      const statsData = data as PromoStat;
      const refinedData = [] as EmailEvent[];
      statsData.playerStats.forEach((ps) =>
        ps.events.forEach((ev) =>
          refinedData.push({
            email: ev.email,
            event: ev.event,
            date: new Date(Number(ev.timestamp) * 1000).toISOString(),
            template: ev.template,
            url: ev.url
          })
        )
      );
      GetCsvData(refinedData, `promo-${statsId}-stats.csv`);
      setStatsId(-1);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const renderCard = ({ item, index }: { item: any; index: number }): React.ReactNode => {
    return <KanbanCard key={`card-${index}`} item={item} index={index} isStatsVisible />;
  };

  const updateStagePromo = useMutation(UpdatePromotionStage, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'info',
          text: `Promotion updated`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  useEffect(() => {
    buildStages();
  }, [promotions]);

  const handleDetails = (id: number) => {
    selectPromo(id, 'details');
  };

  const handleEdit = (id: number) => {
    selectPromo(id, 'edit');
  };

  const selectPromo = (id: number, type: 'edit' | 'details') => {
    const promotion = promotions.find((p) => p.id === id);
    if (promotion) {
      const promoGroup = groups.find((g) => g.name === group);
      promotion.group = promoGroup as IPromotionGroup;
      setSelectedPromo(promotion as IPromotion);
      type === 'edit' ? setOpenForm(true) : setOpenDetails(true);
    }
  };

  const handleDelete = (id: number) => {
    const promo = promotions.find((p) => p.id === id);
    if (promo) {
      dispatch({
        type: Actions.ShowConfirmation,
        payload: {
          text: `Are you sure you want to delete ${promo.name} promotion`,
          agreeAction: () => {
            dispatch({
              type: Actions.HideConfirmation
            });
            deletePromo.mutate(id);
          }
        }
      });
    } else {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: `Invalid promotion id - ${id}`,
          autoHide: 6000
        }
      });
    }
  };

  const handleDownload = (id: number) => {
    setStatsId(id);
    statsData.mutate({ brandId, id });
  };

  const buildStages = useCallback(() => {
    const items = {
      pending: [],
      active: [],
      finished: [],
      deactivated: []
    } as StageItems;

    const now = dayjs();
    promotions.forEach((p) => {
      const promo = {
        id: p.id,
        title: p.name,
        startDate: p.startDate,
        endDate: p.endDate,
        group,
        handleEdit,
        handleDetails,
        handleDelete,
        handleDownload
      } as KanbanItem;
      if (p.isDeactivated) {
        items.deactivated.push(promo);
      } else {
        if (dayjs(p.startDate).isBefore(now)) {
          const finished = dayjs(p.endDate).isBefore(now);
          if (finished) {
            items.finished.push(promo);
          } else {
            items.active.push(promo);
          }
        } else {
          items.pending.push(promo);
        }
      }
    });
    const result = [
      {
        title: PromotionStage.Pending,
        items: items.pending.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate))),
        icon: <QueryBuilderOutlined sx={{ color: '#3364E1' }} />
      },
      {
        title: PromotionStage.Active,
        items: items.active.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate))),
        icon: <CachedOutlined sx={{ color: '#FFDD00' }} />
      },
      {
        title: PromotionStage.Finished,
        items: items.finished.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))),
        icon: <CheckCircleOutlined sx={{ color: '#36CD33' }} />
      },
      {
        title: PromotionStage.Deactivated,
        items: items.deactivated.sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate))),
        icon: <RemoveCircleOutline sx={{ color: '#EF2929' }} />
      }
    ] as KanbanStage[];

    setStages(result);
  }, [promotions]);

  const handleItemStageChange = (sourceName: string, destinationName: string, item: any) => {
    if (sourceName !== destinationName) {
      const movedPromo = item as IPromotion;
      const now = dayjs();
      const start = dayjs(movedPromo.startDate);
      const end = dayjs(movedPromo.endDate);

      switch (destinationName) {
        case PromotionStage.Pending:
          movedPromo.startDate = now.add(1, 'day').startOf('day').toDate();
          if (end.isBefore(now)) {
            movedPromo.endDate = now
              .add(2, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();
          }
          break;
        case PromotionStage.Active:
          if (start.isAfter(now)) {
            movedPromo.startDate = now.add(-1, 'day').startOf('day').toDate();
          }
          if (end.isBefore(now)) {
            movedPromo.endDate = now
              .add(2, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();
          }
          break;
        case PromotionStage.Finished:
          if (end.isAfter(now)) {
            movedPromo.endDate = now
              .add(-1, 'day')
              .endOf('day')
              .set('minute', 50)
              .set('second', 0)
              .toDate();
          }
          if (dayjs(movedPromo.endDate).isBefore(start)) {
            movedPromo.startDate = dayjs(movedPromo.endDate).add(-1, 'day').toDate();
          }
          break;
        case PromotionStage.Deactivated:
          movedPromo.isDeactivated = true;
          break;
        default:
          break;
      }
      const updated = {
        ...movedPromo,
        startDate: dayjs(movedPromo.startDate).utc(true).format(),
        endDate: dayjs(movedPromo.endDate).utc(true).format()
      } as IPromoStage;
      updateStagePromo.mutate(updated);
    }
  };

  const kanbanBoardKey = promotions.map((promo) => promo.id).join('-');

  return (
    <Box>
      <KanbanBoard
        key={kanbanBoardKey}
        stages={stages}
        handleStageChange={handleItemStageChange}
        renderCard={renderCard}
      />
      {openForm && (
        <PromotionForm
          promotion={selectedPromo as IPromotion}
          onClose={() => {
            setOpenForm(false);
            setSelectedPromo(null);
          }}
          groups={groups}
        />
      )}
      {openDetails && (
        <PromoStats
          id={selectedPromo?.id ?? -1}
          promoName={selectedPromo?.name ?? ''}
          onClose={() => {
            setOpenDetails(false);
            setSelectedPromo(null);
          }}
        />
      )}
      <Loader loading={statsData.isLoading} />
    </Box>
  );
};

export default GroupDetails;
