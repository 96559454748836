import React, { useState } from 'react';
import { Box, Button, ButtonGroup, InputLabel, TextField } from '@mui/material';
//* Models
import { IPopup } from '../../../../../../models/popups';
//* Enums
//* Queries
//* Utils
//* Styles
import './PopupHtmlContentStep.scss';
import { ScreenType } from '../../../../../../enums/AppConsts';

interface IProps {
  popupDetails: IPopup;
  setPopupDetails: (value: IPopup) => void;
}

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  overflowX: 'auto',
  overflowY: 'hidden'
} as React.CSSProperties;

const PopupHtmlContentStep: React.FunctionComponent<IProps> = ({
  popupDetails,
  setPopupDetails
}: IProps) => {
  const [viewType, setViewType] = useState(ScreenType.Desktop);

  return (
    <Box className="popup__html-content-step">
      <InputLabel htmlFor="html-content" sx={{ marginBottom: '4px' }}>
        HTML Content
      </InputLabel>
      <TextField
        multiline
        fullWidth
        minRows={10}
        maxRows={10}
        id="html-content"
        className="html-input"
        value={popupDetails.htmlContent}
        onChange={(event) => setPopupDetails({ ...popupDetails, htmlContent: event.target.value })}
      />
      {popupDetails.htmlContent.length > 0 && (
        <>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              variant={viewType === ScreenType.Desktop ? 'contained' : 'outlined'}
              onClick={() => setViewType(ScreenType.Desktop)}
            >
              {ScreenType.Desktop}
            </Button>
            <Button
              variant={viewType === ScreenType.Mobile ? 'contained' : 'outlined'}
              onClick={() => setViewType(ScreenType.Mobile)}
            >
              {ScreenType.Mobile}
            </Button>
          </ButtonGroup>
          <Box className="html-parser">
            <Box className={`html-parser__content html-parser__content--${viewType}`}>
              <iframe
                title="Preview"
                allowFullScreen
                style={iframeStyles}
                srcDoc={popupDetails.htmlContent}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PopupHtmlContentStep;
