import axios from 'axios';
import { IPopup, IPopupSchedule, IPopupScheduleStage } from '../models/popups';
import { baseUrl, getHttpHeaders } from '../utils/rights';

export const GetPopups = async ({ brandId }: { brandId: number }) => {
  return await axios.get(`${baseUrl}/popup/getAll/${brandId}`, getHttpHeaders());
};

export const CreatePopup = async ({
  brandId,
  popupDetails
}: {
  brandId: number;
  popupDetails: IPopup;
}) => {
  return await axios.post(`${baseUrl}/popup/create/${brandId}`, popupDetails, getHttpHeaders());
};

export const UpdatePopup = async ({ id, popupDetails }: { id: number; popupDetails: IPopup }) => {
  return await axios.put(`${baseUrl}/popup/update/${id}`, popupDetails, getHttpHeaders());
};

export const DeletePopup = async (id: number) => {
  return await axios.delete(`${baseUrl}/popup/delete/${id}`, getHttpHeaders());
};

export const UpdatePopupIsActive = async (data: { brandId: number; emailEventId: number }) => {
  return await axios.put(`${baseUrl}/popup/isactive`, data, getHttpHeaders());
};

export const GetPopupsScheduleItemsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/Popup/schedule/getall/${brandId}`, getHttpHeaders());
};

export const CreatePopupSchedule = async ({
  input,
  brandId
}: {
  brandId: number;
  input: IPopupSchedule;
}) => {
  return await axios.post(`${baseUrl}/popup/schedule/create/${brandId}`, input, getHttpHeaders());
};

export const UpdatePopupSchedule = async ({ id, input }: { id: number; input: IPopupSchedule }) => {
  return await axios.put(`${baseUrl}/popup/schedule/update/${id}`, input, getHttpHeaders());
};

export const DeletePopupSchedule = async (id: number) => {
  return await axios.delete(`${baseUrl}/popup/schedule/delete/${id}`, getHttpHeaders());
};

export const UpdatePopupScheduleStage = async (input: IPopupScheduleStage) => {
  return await axios.put(
    `${baseUrl}/popup/schedule/stageupdate/${input.id}`,
    input,
    getHttpHeaders()
  );
};
